<style>
.add-owner {
    float: right;
}

.ranges {
    max-height: 200px;
    overflow: scroll;
}



.alert-primary {
    width: 100%;
}
</style>

<template>
    <div>


        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Filter Location</h5>
            </b-card-header>
            <b-card-body>

                <b-row v-if="role.id == 5">
                    <b-col cols="3">
                        <b-form-group labe-for="region_id" label="Region">
                            <validation-provider #default="{ errors }" name="Region" rules="">
                                <b-form-select id="region_id" name="region_id" size="md" v-model="filter.region_id"
                                    @change="changeRegion(filter.region_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locations" :key="location.id"
                                        :value="location">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.region_id">{{
                                    serverErrors.region_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md" v-model="filter.location_id"
                                    @change="getAreaMarket(filter.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locId.children" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="market_id" label="CPP">
                            <validation-provider #default="{ errors }" name="CPP" rules="">
                                <b-form-select id="market_id" name="market_id" size="md" v-model="filter.market_id"
                                    @change="getAreaMarketSale(filter.market_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                        {{ market.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                                    serverErrors.market_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="sale_id" label="Purchase">
                            <validation-provider #default="{ errors }" name="Purchase" rules="">
                                <b-form-select id="sale_id" name="sale_id" size="md" v-model="filter.sale_id"
                                    @change="changeSale($event)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="sale in sales" :key="sale.id" :value="sale">
                                        {{ sale.number }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{
                                    serverErrors.sale_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="society_id" label="SubZone">
                            <validation-provider #default="{ errors }" name="SubZone" rules="">
                                <b-form-select id="society_id" name="society_id" size="md" v-model="filter.society_id"
                                    @change="changeSociet(filter.society_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="society in societies" :key="society.id"
                                        :value="society.id">
                                        {{ society.name }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.society_id">{{
                                    serverErrors.society_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="Document type" label="Document Type">
                            <validation-provider #default="{ errors }" name="doc_type" rules="">
                                <b-form-select id="doc_type" name="doc_type" size="md" v-model="filter.doc_type"
                                    @change="changeSociet(filter.doc_type)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option value="1">
                                       Pre Market PCN
                                    </b-form-select-option>

                                    <b-form-select-option value="2">
                                       Captered PCN
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.doc_type">{{
                                    serverErrors.doc_type[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col> -->


                </b-row>

                <b-row v-if="role.id != 5">
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md" v-model="filter.location_id"
                                    @change="getAreaMarket(filter.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locId.children" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="market_id" label="CPP">
                            <validation-provider #default="{ errors }" name="CPP" rules="">
                                <b-form-select id="market_id" name="market_id" size="md" v-model="filter.market_id"
                                    @change="getAreaMarketSale(filter.market_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                        {{ market.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                                    serverErrors.market_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="sale_id" label="Purchase">
                            <validation-provider #default="{ errors }" name="Purchase" rules="">
                                <b-form-select id="sale_id" name="sale_id" size="md" v-model="filter.sale_id"
                                    @change="changeSale($event)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="sale in sales" :key="sale.id" :value="sale">
                                        {{ sale.number }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{
                                    serverErrors.sale_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="society_id" label="SubZone">
                            <validation-provider #default="{ errors }" name="SubZone" rules="">
                                <b-form-select id="society_id" name="society_id" size="md" v-model="filter.society_id"
                                    @change="changeSociet(filter.society_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="society in societies" :key="society.id"
                                        :value="society.id">
                                        {{ society.name }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.society_id">{{
                                    serverErrors.society_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
<!-- 
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="Document type" label="Document Type">
                            <validation-provider #default="{ errors }" name="doc_type" rules="">
                                <b-form-select id="doc_type" name="doc_type" size="md" v-model="filter.doc_type"
                                    @change="changeSociet(filter.doc_type)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option value="1">
                                       Pre Market PCN
                                    </b-form-select-option>

                                    <b-form-select-option value="2">
                                       Captered PCN
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.doc_type">{{
                                    serverErrors.doc_type[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col> -->


                </b-row>

                <b-row class="mt-2">
                    <b-col>
                        <b-form-checkbox class="custom-control-success" v-model="pc"    @change="updateCheckbox(1)"> Purchase Contract</b-form-checkbox>
                    </b-col>
                    <b-col>
                        <b-form-checkbox class="custom-control-success" v-model="gpc"  @change="updateCheckbox(2)"> Generated PCN Report</b-form-checkbox>
                    </b-col>
                    <b-col>
                        <b-form-checkbox class="custom-control-success" v-model="mdf"  @change="updateCheckbox(3)"> Market Declaration Form</b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="3">
                        <div class="mt-10" style="margin-top: 35px">
                            <b-button variant="primary" size="sm" @click="getPcn()">
                                <span class="text-nowrap" v-if="saleInfo.printed">Re-Print Documents</span>
                                <span class="text-nowrap" v-if="!saleInfo.printed">Print Documents</span>
                            </b-button>
                        </div>

                    </b-col>
                </b-row>

                <b-row>

                    <table class="table table-striped mt-3">
    <thead>
      <tr>
        <th>Check</th>
        <th>Pcn Number</th>
        <th>Start Number</th>
        <th>End Number</th>
        <th>Bales</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="aggrement in saleInfo.agreements">
        <td>
            <b-form-checkbox class="custom-control-success"  @change="checkboxToggled(aggrement)">
                    </b-form-checkbox>
        </td>
        <td>{{aggrement.number}}</td>
        <td>{{aggrement.start}}</td>
        <td>{{aggrement.end}}</td>
        <td>{{ aggrement.tickets.length }}</td>
      </tr>
      
    </tbody>
  </table>
                    <!-- <pre>
                    {{ filter }}
                 </pre> -->
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox, BBadge, BAlert, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import salesDocumentsStoreModule from '@/views/cromis/sales/sales_documents/salesDocumentsStoreModule'
import useSalesDocumentsList from '@/views/cromis/sales/sales_documents/useSalesDocumentsList'
import Panel from 'primevue/panel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, BBadge, BAlert, Panel, Accordion, AccordionTab, BFormDatepicker
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },
    setup(props, context) {
        const dataForm = ref(null)
        const myModalPcn = ref(null)
        const myViewModal = ref(null)
        const myRangeModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const totalMass = ref(null)
        const totalValue = ref(null)
        const errosOcurs = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const totalWeight = ref(0)
        const tValue = ref(0)
        const companies = ref([])
        const societies = ref([])
        const markets = ref([])
        const requisitionDetail = ref(null)
        const locations = ref([])
        const sales = ref([])
        const children = ref([])
        const tikets = ref([])
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const minDate = new Date(today)
        const saleInfo = ref({})
        const pc = ref(true)
        const gpc = ref(true)
        const mdf = ref(true)
        const reports = ref([1,2,3])
        const agreeIds = ref([])
        const filter = ref({
            region_id: null,
            location_id: null,
            market_id: null,
            sale_id: null,
            society_id: null,
            doc_type: null
        })

     

        const locId = ref(JSON.parse(localStorage.getItem('userData')).location)
        const role = ref(JSON.parse(localStorage.getItem('userData')).roles[0])
        console.log(locId)





        const CROMIS_STORE_MODULE_NAME = 'cromis-sale_documents'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, salesDocumentsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {


            await store.dispatch('cromis-sale_documents/locations')
                .then(response => {
                    locations.value = response.data.locations

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const populateSelections = (locations) => {
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }

        // Form Methods
        const viewPcn = (item) => {
            tikets.value = item

            for (let index = 0; index < tikets.value.length; index++) {
                console.log(tikets.value[index])
                totalWeight.value += parseFloat(tikets.value[index].mass);
                tValue.value += parseFloat(tikets.value[index].value);

            }

            myModalPcn.value.show()
        }

        const approveRequisition = (item) => {
            console.log(item)
            handleApprove(item)
        }

        const getTotalValue = (valueArray) => {
            console.log(valueArray)
            totalValue.value = 0;
            for (let index = 0; index < valueArray.length; index++) {
                totalValue.value = valueArray[index].mass;

            }
            // valueArray.forEach(ele => {
            //     totalValue.value += ele.value;
            // });



            return totalValue.value;
        }

        const getTotalMass = (massArray) => {
            totalMass.value = 0;
            // console.log(massArray)
            // massArray.forEach(ele => {
            //     totalMass.value += ele.mass;
            // });

            for (let index = 0; index < massArray.length; index++) {
                totalMass.value = massArray[index].value;

            }

            return totalMass.value;
        }

        const updateCheckbox = (value) => {
      // Toggle value based on checkbox state
      if (value === 1) {
        pc.value ? toggleValue(1) : removeValue(1);
      } else if (value === 2) {
        gpc.value ? toggleValue(2) : removeValue(2);
      } else if (value === 3) {
        mdf.value ? toggleValue(3) : removeValue(3);
      }

      console.log(reports.value)
    };

    const toggleValue = (value) => {
      // Toggle value in reports array
      if (!reports.value.includes(value)) {
        reports.value.push(value); // Add value if it doesn't exist
      }
    };

    const removeValue = (value) => {
      // Remove value from reports array
      const index = reports.value.indexOf(value);
      if (index !== -1) {
        reports.value.splice(index, 1); // Remove value if it exists
      }
    };

        const getPcn = async () => {
            console.log(filter.value)
            filter.value.sale_id = filter.value.sale_id.id
            let data = {
                "society_id": filter.value.society_id,
                "sale_id": filter.value.sale_id,
                "print": true,
                "reports": reports.value,
                "agreements": agreeIds.value
                
            }
            console.log(data)

            await store.dispatch('cromis-sale_documents/create', data)
                .then(response => {
                   
                    context.root.$swal({
                        icon: 'success',
                        text: 'Purchase Document prints initialized!',
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                   
                })
                .catch(error => {
                    console.log(error)
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })

        }

        const checkboxToggled = async (item) => {
            agreeIds.value.push(item.id)

        }

        const handleCheckboxChange = async (event, id) => {

            console.log(event)
            let payload = {
                "sale_documentsd": event
            }

            await store.dispatch('cromis-sale_documents/update', { id: id, data: payload })
                .then(response => {

                    console.log(response)
                    refetch()
                    saving.value = false


                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to PCN has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const getAreaMarket = async () => {
            await store.dispatch('cromis-sale_documents/markets', { location_id: filter.value.location_id })
                .then(response => {
                    markets.value = response.data.markets

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const getAreaMarketSale = async () => {
            await store.dispatch('cromis-sale_documents/sales', { market_id: filter.value.market_id })
                .then(response => {
                    sales.value = response.data.sales
                    societies.value = response.data.sales.societies
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeSociet = async (id) => {

            // societyFilter.value = id
            let data = {
                "society_id": id,
                "sale_id": filter.value.sale_id.id,
                "print":false
            }
            console.log(data)


            await store.dispatch('cromis-sale_documents/create', data)
                .then(response => {
                    console.log(response)
                    saleInfo.value = response.data

                    console.log(saleInfo.value);
                })
                .catch(error => {
                    console.log(error)
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const changeSale = async (event) => {
            societies.value = event.societies
            // filter.value.sale_id = event.id
        }

        const changeRegion = async (event) => {

            locId.value = event
            console.log(locId.value)
            // filter.value.sale_id = event.id
        }




        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            handleCreate()
        }



        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }



        }



        const handleApprove = async (item) => {

            saving.value = true

            let data = {
                "approved": true
            }

            console.log(item)
            console.log(data)

            await store.dispatch('cromis-sale_documents/update', { id: item.id, data: data })
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: `Approved has been made successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    console.log(error.response)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors

                        console.log(serverErrors)
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }



        const remove = async (id) => {
            await store.dispatch('cromis-sale_documents/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
           

            selectedOwner: null,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            // Methods

            required,
            saving,
            serverErrors,
            dataForm,
            myModalPcn,
            myViewModal,
            requisitionDetail,
            filter,
            markets,
            locations,
            sales,
            changeSale,
            changeSociet,
            changeRegion,
            myRangeModal,
           
            viewPcn,
            approveRequisition,
            submit,
            isFormValid,
            handleCreate,
            companies,
            societies,
            errosOcurs,
            locId,
            role,
            getAreaMarket,
            getAreaMarketSale,
            handleApprove,
            minDate,
            getPcn,
            handleCheckboxChange,
            getTotalMass,
            getTotalValue,
            totalMass,
            totalValue,
            tikets,
            totalWeight,
            tValue,
            saleInfo,
            checkboxToggled,
            pc,
            gpc,
            mdf,
            updateCheckbox,
            reports,
            toggleValue,
            removeValue
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>